import { SelectProps } from "antd";

export type RoleType = 'kitchen' | 'totem';

export type Period = 'daily' | 'weeklySpendLimit' | 'monthlySpendLimit' | 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'

export interface User {
  id: string;
  username: string;
  role: RoleType | null;
  entity: string;
  token: string;
  refreshToken: string;
  terminalId?: string;
}

export interface VirtualCard {
  id: string;
  balance: number;
  minimumBalance: number;
}

export interface Wallet {
  period: Period;
  periodLimit: number;
  spent: number;
}

export interface Totem {
  id: string;
  identityNumber: string;
  picture: string;
  name: string;
  role: string;
  paymentInfos: any[] | null;
  email: string | null;
  virtualCard: VirtualCard | null;
  wallet: Wallet;
  userId: string;
}

export interface Category {
  id: string;
  name: string;
  picture: string;
}

export type CategoryArray = Category[]

export interface Product {
  id: string;
  name: string;
  description: string;
  picture: string;
  cost: number;
  price: number;
  quantity: number;
  amount: never;
  totalAmount: number;
  isLocked?: boolean;
  isActive: boolean;
  observation?: string;
  isHighlighted: boolean | null;
  isvariablePrice?: boolean;
  discountPrice?: number;
  priceWithoutDiscount?: number;
  unitPrice?: number;
  productCategory: {
    id: string,
    name: string,
    picture: string,
    isActive: boolean
  }
  segment?: {
    id?: string,
    name?: string,
    isActive?: boolean
  }
}

export type ProductArray = Product[]

export interface Item extends Omit<Product, 'amount'> {
  amount: number;
  maxAmount?: number;
  observation?: string;
}

export type ItemArray = Item[]

export interface Order {
  id?: string;
  _id?: string;
  items: ItemArray;
  totalAmount: number;
  createdAt?: string;
  isOnCredit?: boolean;
  clientSignature?: string;
  clientName?: string;
  clientId?: string;
  adminClientName?: string;
  adminClientEmail?: string;
  adminClientPhone?: string;
  isCanceled?: string;
  isSchedule?: boolean;
  scheduleFor?: string;
}

export type Orders = Order[];

type SectorOrder = {
  sector: string;
}

export type KitchenOrderClient = Pick<Totem & SectorOrder, 'id' | 'name' | 'picture' | 'identityNumber' | 'sector'>;

export interface KitchenOrder extends Order {
  id: string;
  client: KitchenOrderClient;
  isDelivered: boolean;
  isPending: boolean;
  isInProgress: boolean;
  deliveredAt?: string;
}

export type KitchenOrders = KitchenOrder[];

type ItemOrder = Pick<Product, "id" | "name" | "quantity" | "price" | "totalAmount"> & {
  observation?: string;
};

export type Items = ItemOrder[];

export interface NewOrder {
  entity: string;
  virtualCard?: string;
  totalAmount: number;
  items: Items;
  isOnCredit?: boolean;
  clientSignature?: string;
  clientName?: string;
  clientId?: string;
  isVariablePrice?: boolean,
  isSelfCheckout?: boolean,
  isIndividual?: boolean,
  managerName?: string,
  managerEmail?: string,
  managerCode?: string,
  adminClientName?: string,
  adminClientEmail?: string,
  adminClientPhone?: string,
}

export interface NewOrderIndividual {
  amount: number;
  adminClient: string;
  entityId: string;
  paymentInfoId: string;
  paymentMethod: string;
  isSelfCheckoutTotem: boolean;
  clientName: string;
  isOnCredit: boolean;
  isIndividual: boolean;
  items: Items;
}

export type AdminEntity = {
  id: string;
  name: string;
  email: string;
  authorizationCode: string;
}

type AdminClient = {
  id: string;
  name: string;
  email: string;
  phone: string;
}

export interface Client {
  id: string;
  name: string;
  email: string;
  picture: string;
  admins?: AdminClient[]
}

export type Clients = Array<Client>

export type UpdateClientPasswordDto = {
  password: string;
  user?: string;
}

export type Sector = {
  id: string;
  name: string;
  isActive: boolean;
  selected?: boolean;
  products: [];
 }

export type SearchableInputItem = {
  label: string;
  value?: any;
  metadata?: any;
}

export type SearchableInputTypes = {
  placeholder?: any;
  label?: string | any;
  data: SearchableInputItem[];
  value: string;
  border?: boolean;
  onChange: (val: string) => void;
  onClick?: (val: any) => void;
  onBlur?: (val: any) => void;
  onSelect?: (item: SearchableInputItem) => void;
}

export type Payment = {
  entity_id: string;
  entity_name: string;
  client_id: string;
  client_name: string;
  virtual_card_id: string;
  transaction_id: string;
  admin_client_name: string;
  transaction_ref: string;
  date_created: string;
  transaction_amount: number;
  is_reimbursement: number;
  is_manual_recharge: number;
  status: string;
};

export type CreatePaymentVisitor = {
    amount: number,
    adminClient?: string,
    isIndividual?: boolean,
    entityId: string
    visitorName?: string
    paymentMethod: string
    isSelfCheckoutTotem: boolean;
    items: Items;
    clientName: string;
    isOnCredit: boolean;
};

export type CreatePaymentOrder = {
  terminalId: string
  reference: string
  entityId: string
  clientName: string
  paymentMethod: number
  application: string
  isSelfCheckoutTotem: boolean
  amount: number
  items: Items
};

export type CreatePaymentOrderResponse = {
    id: string,
    terminalId: string,
    reference: string,
    entityId: string,
    clientName: string,
    paymentMethod: number,
    application:string
    amount: number,
    installmentQuantity: number,
    items: Items
};

export type CreatePaymentVisitorResponse = {
  createdAt: string,
  paymentMethod: string,
  status: string,
  statusDescription: string,
  qrCode: string,
  qrCodeKey: string,
  id: string,
  completedAt: null,
  virtualCard: null,
  adminClient: null,
  isReimbursement: boolean,
  isManualRecharge: boolean,
  transactionId: number,
  amount: number,
  qrCodeExpirationTime: number,
};

export type SelectItem = {
  name: string;
  value: string;
}

export type SelectInputProps = SelectProps& {
  label?: string
  labelStyle?: any
}

export type CheckFace = {
  image: string
  entity: string
};

export enum PaymentMethod {
  PIX = "6",
  DEBIT_CARD = "4",
  CREDIT_CARD = "2",
}
