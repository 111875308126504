import React from "react";
import { useLocation } from "react-router-dom";
import {
  PaymentMethod,
} from "../../types";
import { TotemTerminalPayment } from "./TotemTerminal";
import { PixTotemPayment } from "./PixTotem";

export const VisitorConfirmPurchase: React.FC = () => {
  const {
    state: { paymentMethod },
  }: {
    state: {
      paymentMethod?: PaymentMethod;
    };
  } = useLocation();

  if (paymentMethod === PaymentMethod.CREDIT_CARD || paymentMethod === PaymentMethod.DEBIT_CARD) {
    return (
      <TotemTerminalPayment />
    );
  }
  if (paymentMethod === PaymentMethod.PIX) {
    return (
      <PixTotemPayment />
    );
  }
  return <div />;
};
