import { Popover, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";

import { ProfileIcon } from "../../assets";

import { createOrder } from "../../services";
import { getFirebaseRealTimeDatabase } from "../../services/firebaseRealTimeDatabase";
import { getWalletByClient } from "../../services/totem";

import { useStore } from "../../store";
import { isWhatPercentOf } from "../../utils/isWhatPercentOf";
import {
  Progress,
  PurchaseView,
  Button,
  FinishModal,
  PaymentMethodModal,
  BlockedModal,
} from "../index";

import useStyles from "./styles";
import { formatCurrency } from "../../utils/format";
import { ClientProps } from "./client.types";
import { VisitorPaymentMethodModal } from "../Modals/VisitorPaymentMethodModal";
import { IndividualPaymentMethodModal } from "../Modals/IndividualPaymentMethodModal";

export const Client = (props: ClientProps) => {
  const {
    userManager, userTotem, order, navigation
  } = useStore();

  const Styles = useStyles({ picture: userTotem.picture });

  const oneSeconds = 1000;
  const thirtySeconds = oneSeconds * 30;

  const [openFinishModal, setOpenFinishModal] = useState<boolean>(false);
  const [openPaymentMethodModal, setOpenPaymentMethodModal] =
    useState<boolean>(false);

  const [openIndividualPaymentMethodModal, setOpenIndividualPaymentMethodModal] = useState<boolean>(false);

  const [countdown, setCountdown] = useState(0);

  const [isFinalizePurchaseLoading, setIsFinalizePurchaseLoading] =
    useState(false);
  const [menuIsOpened, setMenuIsOpened] = useState(false);
  const [openBlockedModal, setOpenBlockedModal] = useState<boolean>(false);
  const [openVisitorCheckoutModal, setOpenVisitorCheckoutModal] =
    useState<boolean>(false);

  const loadWallet = async () => {
    if (userTotem.id) {
      const wallet = await getWalletByClient(userTotem.id);

      if (wallet) userTotem.updateWallet(wallet);
    }
  };

  const isBlockedProductLimit = (): boolean =>
    order.totalAmount / 100 >
      userTotem.wallet.periodLimit - userTotem.wallet.spent && !!userTotem.id;
  const isVisitor = (): boolean => !!props?.visitor;
  const Limit = formatCurrency(
    userTotem.wallet.periodLimit - userTotem.wallet.spent
  );

  const finalizePurchase = async () => {
    setIsFinalizePurchaseLoading(true);
    // TODO: Olhar aqui
    const data = {
      entity: userManager.entity,
      virtualCard: userTotem.virtualCard?.id,
      totalAmount: order.totalAmount,
      clientName: userTotem.name,
      isOnCredit: order.isOnCredit,
      items: order.items.map((item) => {
        const {
          id, name, amount, totalAmount, price, observation,
        } = item;

        return {
          id,
          name,
          quantity: amount,
          price,
          totalAmount,
          observation,
        };
      }),
    };

    const res = await createOrder(data);

    if (res) {
      setOpenFinishModal(true);
      setIsFinalizePurchaseLoading(false);
    } else {
      console.log("Falha");
      setIsFinalizePurchaseLoading(false);
    }
  };

  const handleFinalizePurchase = () => {
    if (isVisitor()) {
      setOpenVisitorCheckoutModal(true);
      return;
    }

    if (userTotem?.role === "adminClient") {
      setOpenIndividualPaymentMethodModal(true);
      return;
    }

    if (isBlockedProductLimit()) {
      setOpenBlockedModal(true);
    } else if (userTotem.id) {
      finalizePurchase();
    } else {
      setOpenPaymentMethodModal(true);
    }
  };

  const handleLogout = async () => {
    navigation.navigateTo("/");

    await userTotem.logout();
  };

  const onIdle = async () => {
    if (userTotem.id) {
      await handleLogout();
    }
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: thirtySeconds,
    onIdle,
  });

  const getLeftTime = (): number => Math.ceil(getRemainingTime() / oneSeconds);

  useEffect(() => {
    setInterval(() => {
      setCountdown(getLeftTime());
    }, oneSeconds);
  }, []);

  useEffect(() => {
    getFirebaseRealTimeDatabase(userManager.entity, loadWallet);
  }, []);

  return (
    <div className={Styles.clientView}>
      {!props?.visitor && (
        <div className={Styles.client}>
          <div className={Styles.clientInfo}>
            <Typography component="span">
              {userTotem.name || "Gerente"}
            </Typography>

            <Progress
              percentage={
                isWhatPercentOf(
                  userTotem.wallet?.periodLimit,
                  userTotem.wallet?.spent
                ) || 0
              }
            />
          </div>
          {userTotem.id && (
            <Popover
              onClose={() => setMenuIsOpened(false)}
              open={menuIsOpened}
              style={{ marginTop: 80 }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div
                onClick={() => {
                  setMenuIsOpened(false);
                  navigation.navigateTo("/update-password");
                }}
                className={Styles.submenuButton}
              >
                <div className={Styles.submenuText}>
                  <Typography component="span">Editar</Typography>
                </div>
              </div>
            </Popover>
          )}

          {userTotem.picture ? (
            <div
              className={Styles.clientPhoto}
              onClick={() => setMenuIsOpened(!menuIsOpened)}
            />
          ) : (
            <div
              className={Styles.picture}
              onClick={() => setMenuIsOpened(!menuIsOpened)}
            >
              <ProfileIcon />
            </div>
          )}
        </div>
      )}

      <PurchaseView totem={userTotem} order={order} onShowSectors={props?.onShowSectors} />

      <div className={Styles.footer}>
        <Button
          color="primary"
          className={Styles.button}
          disabled={order.items.length === 0 || isFinalizePurchaseLoading}
          onClick={handleFinalizePurchase}
        >
          Finalizar Compra
        </Button>

        <Button
          color="secondary"
          className={Styles.button}
          onClick={handleLogout}
        >
          {userTotem.id ? `Sair automaticamente em ${countdown}s` : "Sair"}
        </Button>
      </div>

      <BlockedModal
        message={`Você está tentando realizar uma compra de: ${formatCurrency(
          order.totalAmount / 100
        )}, mas seu saldo disponível do limite é: ${Limit}, Por favor Verifique seu carrinho `}
        open={openBlockedModal}
        onClose={() => setOpenBlockedModal(false)}
      />
      <FinishModal open={openFinishModal} />
      <PaymentMethodModal open={openPaymentMethodModal} />
      <VisitorPaymentMethodModal open={openVisitorCheckoutModal} onClose={() => setOpenVisitorCheckoutModal(false)} />
      <IndividualPaymentMethodModal open={openIndividualPaymentMethodModal} />
    </div>
  );
};
