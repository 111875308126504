import { makeStyles, Theme } from "@material-ui/core/styles";
import { OrderSchedule } from "./index";

const paddingCard = 20;
const borderRadius = 14;

type DynamicStylesProps = Pick<OrderSchedule, "client">;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    width: "100%",
    marginBottom: 20,
  },

  orderInfos: {
    width: 500,
    padding: paddingCard,
    borderTopLeftRadius: borderRadius,
    borderBottomLeftRadius: borderRadius,
    backgroundColor: theme.palette.background.paper,
  },

  orderTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  orderOwner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 258,
  },

  collunName: {
    display: "flex",
    flexDirection: "column",
  },

  ownerPhoto: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 42,
    height: 42,
    borderRadius,
    backgroundColor: theme.palette.secondary.main,
  },

  picture: {
    width: "100%",
    height: "100%",
    borderRadius,
    backgroundImage: ({ client }: DynamicStylesProps) =>
      client ? `url(${client?.picture})` : "none",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
  },

  profileIcon: {
    height: 20,
  },

  ownerName: {
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: 16,
    width: 175,
  },

  ownerEnrollment: {
    fontSize: 10,
    marginLeft: 16,
    width: 175,
  },

  orderActions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: 110,
  },

  action: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 30,
    height: 30,
    borderRadius: 8,
    backgroundColor: theme.palette.secondary.main,

    "& svg": {
      height: 18,
    },
  },

  orderTime: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: 16,
    "& span": {
      fontSize: 12,
      fontWeight: "bold",
    },
  },

  products: {
    marginTop: 16,
  },

  product: {
    display: "flex",
    flexDirection: "row",
  },

  amount: {
    width: 33,

    "& span": {
      fontSize: 14,
      fontWeight: "bold",
    },
  },

  name: {
    display: "flex",
    width: 355,
    "& span": {
      fontSize: 14,
      fontWeight: "bold",
    },
  },

  orderAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 65,
    borderTopRightRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
    backgroundColor: "#000",
    color: "#FFF",
  },

  observation: {
    display: "flex",
    width: 355,
    justifyContent: "flex-start",
    "& span": {
      fontSize: 10,
      width: 300,
      wordWrap: "break-word",
      overflowWrap: "break-word",
      whiteSpace: "normal",
    },
  },

  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  "@media (max-width: 1024px)": {
    ownerName: {
      width: 150,
    },

    orderOwner: {
      width: "60%",
    },

    orderInfos: {
      width: "85%",
    },

    orderActions: {
      paddingLeft: 10,
    },
  },
}));

export default useStyles;
