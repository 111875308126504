import { makeStyles } from "@material-ui/core/styles";
import { KitchenBg } from "../../../assets/images";

const useStyles = makeStyles(() => ({
  modal: {
    backgroundColor: "#000",
    padding: 70,
    backgroundImage: `url(${KitchenBg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
  },

  view: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",

    "& span": {
      fontSize: 34,
      fontWeight: "bold",
      color: "#FFF",
      marginBottom: 16,
    },
  },

  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 16,
    width: "100%",
    marginTop: 16,
  },
}));

export default useStyles;
