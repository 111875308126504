import { yupResolver } from "@hookform/resolvers/yup";
import { Slide, Typography } from "@material-ui/core";
import React, {
  useCallback,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { BackspaceIcon } from "../../assets";
import {
  Button, FormFieldTwoFactor, LoadingModal
} from "../../components";
import {
  LoginAdministrativeSchema,
  LoginAdministrativeSchemaInterface,
} from "../../schemas";
import { cancelOrder, createOrder, loginAdmin } from "../../services";
import { useStore } from "../../store";
// import { NavigationInterface } from "../../store/hooks";
import { generateTwoFactorArray } from "../../utils/arrayUtils";
import useStyles from "./styles";

type Fields = keyof LoginAdministrativeSchemaInterface;

const initialLoginFormValues: LoginAdministrativeSchemaInterface = {
  password: '',
};

export const LoginAdministrative: React.FC = () => {
  const Styles = useStyles();

  const {
    state,
  }: any = useLocation();

  const { userManager, navigation, order } = useStore();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { isValid }
  } =
    useForm<LoginAdministrativeSchemaInterface>({
      mode: "onChange",
      resolver: yupResolver(LoginAdministrativeSchema),
      defaultValues: initialLoginFormValues,
    });

  const [focusedField, setFocusedField] = useState<Fields | null>(null);

  const [optionArray, setOptionArray] = useState<Array<Array<number>>>([]);
  const [showKeyboard, setShowKeyboard] = useState<boolean>(false);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  const [modalMessage, setModalMessage] = useState<string>("");

  const [managerName, setManagerName] = useState<string>("");
  const [managerEmail, setManagerEmail] = useState<string>("");
  const [managerCode, setManagerCode] = useState<string>("");

  const handleOnFocusField = (
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    setFocusedField(event.target.name as Fields);

    event.target.blur();
  };

  const handleNumberClick = useCallback(
    (numbers: Array<number>) => {
      if (focusedField) {
        const value = getValues()[focusedField] || "";

        // esse espaço entre value e numbers é importante para o split depois, não remover
        setValue(focusedField, `${value} ${numbers.toString()}`, { shouldValidate: true });
      }
    },
    [focusedField]
  );

  const handleBackspacePassword = useCallback(() => {
    if (focusedField) {
      setValue(focusedField, "", { shouldValidate: true });
    }
  }, [focusedField]);

  const handleBack = useCallback(() => {
    reset(initialLoginFormValues);

    if (userManager.role === "kitchen") {
      navigation.navigateTo('/real-time');
    } else if (state.ismanual) {
      navigation.navigateTo('/confirm-manual-purchase', {
        state: {
          type: "manual"
        }
      });
    } else if (state?.isLogount) {
      navigation.navigateTo('/login-user');
    } else {
      navigation.navigateTo('/confirm-manual-purchase', {
        state: {
          type: "on-credit"
        }
      });
    }
  }, [userManager.role]);

  const submitForm = async () => {
    if (state?.order) {
      if (userManager.role === "kitchen") {
        const items = state?.order?.items.map((o: any) => ({
          id: o.id,
          quantity: 0,
          price: o.price,
        }));

        const result = await cancelOrder(state?.order?.id, items);

        setModalMessage(result ? "Pedido Deletado" : "Falha ao Deletar Pedido");

        setTimeout(() => {
          navigation.navigateTo(
            state?.isSchedule ? "/scheduled-order" : "/real-time"
          );
          setModalMessage("");
        }, 3000);
      }
    } else if (order.items.length > 0) {
      const {
        isOnCredit, clientSignature, clientId, adminClientName, adminClientEmail, adminClientPhone
      } = order;
      const res = await createOrder({
        entity: userManager.entity,
        totalAmount: order.totalAmount,
        isOnCredit,
        clientSignature,
        clientId,
        isVariablePrice: !!state.isVariablePrice,
        clientName: order.clientName
          ? order.clientName.trim()
          : order.clientName,
        managerName,
        managerEmail,
        managerCode,
        adminClientName,
        adminClientEmail,
        adminClientPhone,
        items: order.items.map((item) => {
          const {
            id, name, amount, price, totalAmount, observation,
          } = item;

          return {
            id,
            name,
            quantity: amount,
            price,
            totalAmount,
            observation,
          };
        }),
      });

      order.reset();

      if (res) {
        setModalMessage("Compra Finalizada");

        setTimeout(() => {
          navigation.navigateTo(
            state.isVariablePrice ? "/variable-price-purchase" : "/login-user"
          );

          setModalMessage("");
        }, 3000);
      } else {
        setModalMessage(
          "Falha ao finalizar sua compra. Verifique o estoque dos produtos"
        );

        setTimeout(() => {
          navigation.navigateTo("/login-user");

          setModalMessage("");
        }, 5000);
      }
    } else if (state?.isLogount) {
      userManager.logout();
      navigation.navigateTo("/");
    } else {
      navigation.navigateTo("/manual-purchase");
    }
  };

  const onSubmit = async (data: LoginAdministrativeSchemaInterface) => {
    const { password } = data;

    const numbersPairs = password.trim().split(' ').map((item) => item.split(','));

    const authorized = await loginAdmin(userManager.entity, numbersPairs);

    if (authorized) {
      if (typeof authorized !== "boolean" && order.items.length > 0 && state.ismanual) {
        setManagerName(authorized.name);
        setManagerEmail(authorized.email);
        setManagerCode(authorized.authorizationCode);
      }
      setIsAuthorized(true);
    }

    reset(initialLoginFormValues);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowKeyboard(!!focusedField);
    }, 500);
  }, [focusedField]);

  useEffect(() => {
    if (isAuthorized) {
      submitForm();
    }
  }, [isAuthorized]);

  useEffect(() => {
    setOptionArray(generateTwoFactorArray());
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.containerRelative}>
        <div className={Styles.formView}>
          <form onSubmit={handleSubmit(onSubmit)}>
            { state?.isLogount ? (
              <Typography className={Styles.title}>Gerente / sair</Typography>
            ) : (
              <Typography className={Styles.title}>{ state.ismanual ? "Gerente / Compra Manual" : "Gerente / Compra Fiado"}</Typography>
            )}

            <FormFieldTwoFactor
              control={control}
              name="password"
              label="Código de autorização"
              placeholder="Insira seu código"
              type="password"
              onFocus={handleOnFocusField}
              focus={(name) => name === focusedField}
            />

            <div className={Styles.actions}>
              <Button
                type="submit"
                size="large"
                fullWidth
                disabled={!isValid}
              >
                Continuar
              </Button>

              <Button
                color="secondary"
                size="large"
                fullWidth
                onClick={handleBack}
              >
                Voltar
              </Button>
            </div>
          </form>
        </div>

        <LoadingModal
          open={!!modalMessage}
          message={modalMessage}
        />

        {
          (focusedField === "password") && (
            <Slide in={showKeyboard} direction="left">
              <div className={Styles.keyboardPassword}>
                {optionArray.map((numbers: Array<number>) => (
                  <div
                    key={numbers[0]}
                    className={Styles.buttonPassword}
                    onClick={() => handleNumberClick(numbers)}
                  >
                    <Typography component="span">{`${numbers[0]} ou ${numbers[1]}`}</Typography>
                  </div>
                ))}

                <div className={Styles.buttonPassword} onClick={handleBackspacePassword}>
                  <BackspaceIcon />
                </div>
              </div>
            </Slide>
          )
        }
      </div>
    </div>
  );
};
