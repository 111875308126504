import { CircularProgress, Typography } from "@material-ui/core";

import {
  ChevronRight,
  DeleteOutlineOutlined,
  CreateOutlined,
  CheckOutlined,
} from "@material-ui/icons";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import React from "react";

import { ProfileIcon } from "../../assets";
import { KitchenOrder } from "../../types";

import useStyles from "./styles";
import { formatCurrency } from "../../utils/format";
import { useEntity } from "../../hooks/useEntity";

export interface Order extends KitchenOrder {
  name: string;
  enrollment: string | null;
  orderAction: (id: string) => void;
  cancelOrder: (id: string) => void;
  selectOrder: () => void;
  handleProfileClick: () => void;
  isLoading?: boolean;
}

export const CardOrder: React.FC<Order> = ({
  id,
  createdAt,
  client,
  items,
  isPending,
  isInProgress,
  name,
  enrollment,
  isLoading,
  orderAction,
  cancelOrder,
  selectOrder,
  handleProfileClick,
}) => {
  const { showObservations } = useEntity();
  const Styles = useStyles({ isPending, isInProgress, client });

  type Item = {
    quantity: number;
    price: number;
    unitPrice?: number;
  };
  const getProductPrices = (productItem: Item) => {
    if (!productItem) return 0;
    return (
      productItem.quantity *
      (productItem.unitPrice ? productItem.unitPrice / 100 : 0)
    );
  };

  const verifyEnrollment = () => {
    if (!enrollment) return null;
    return (
      <Typography component="span" className={Styles.ownerEnrollment} noWrap>
        {enrollment}
      </Typography>
    );
  };

  return (
    <div className={Styles.card}>
      <div className={Styles.orderInfos}>
        <div className={Styles.orderTop}>
          <div className={Styles.orderOwner}>
            <div className={Styles.ownerPhoto}>
              {client?.picture ? (
                <div className={Styles.picture} onClick={handleProfileClick} />
              ) : (
                <ProfileIcon className={Styles.profileIcon} />
              )}
            </div>

            <div className={Styles.collunName}>
              <Typography component="span" className={Styles.ownerName} noWrap>
                {name}
              </Typography>
              {verifyEnrollment()}
            </div>
          </div>

          <div className={Styles.orderActions}>
            <div className={Styles.action} onClick={() => cancelOrder(id)}>
              <DeleteOutlineOutlined />
            </div>

            <div className={Styles.action} onClick={selectOrder}>
              <CreateOutlined />
            </div>
          </div>

          {createdAt && (
            <div className={Styles.orderTime}>
              <Typography component="span">
                {format(new Date(createdAt), "HH:mm", { locale: ptBR })}
              </Typography>
            </div>
          )}
        </div>

        <div className={Styles.products}>
          {items
            .filter((_item) => _item.quantity > 0)
            .map((item) => (
              <div key={item.id} className={Styles.product}>
                <div className={Styles.amount}>
                  <Typography component="span">{item.quantity}</Typography>
                </div>

                {showObservations && item.observation ? (
                  <div className={Styles.details}>
                    <div className={Styles.name}>
                      <Typography component="span" noWrap>
                        {item.name}
                      </Typography>
                    </div>
                    <div className={Styles.observation}>
                      <Typography component="span">
                        Observação:
                        {' '}
                        {item.observation}
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <div className={Styles.name}>
                    <Typography component="span" noWrap>
                      {item.name}
                    </Typography>
                  </div>
                )}

                <div className={Styles.price}>
                  <Typography component="span">
                    {formatCurrency(getProductPrices(item))}
                  </Typography>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className={Styles.orderAction} onClick={() => orderAction(id)}>
        {isPending ? (
          <>{!isLoading ? <ChevronRight /> : <CircularProgress />}</>
        ) : (
          <>{!isLoading ? <CheckOutlined /> : <CircularProgress />}</>
        )}
      </div>
    </div>
  );
};
