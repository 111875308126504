import { PaymentMethod } from "../types";

export const getPaymentMethodName = (paymentMethod?: PaymentMethod): string => {
  if (paymentMethod === PaymentMethod.PIX) {
    return "Pix";
  } if (paymentMethod === PaymentMethod.DEBIT_CARD) {
    return "Cartão de débito";
  } if (paymentMethod === PaymentMethod.CREDIT_CARD) {
    return "Cartão de crédito";
  }
  return "-";
};
