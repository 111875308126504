import api from "../configs/api";

import {
  User,
  Totem,
  Wallet,
  CheckFace,
  AdminEntity,
} from "../types";

import {
  deleteLocalStorage,
  getLocalStorage,
  LocalStorageBaseKey,
  setLocalStorage,
} from "./storage";
import { getWalletByClient } from "./totem";

export const localStorageKeyUserManager: LocalStorageBaseKey = "UserManager";
export const localStorageKeyUserTotem: LocalStorageBaseKey = "UserTotem";
export const localStorageKeyUserToken: LocalStorageBaseKey = "UserToken";
export const localStorageKeyUserRefreshToken: LocalStorageBaseKey = "UserRefreshToken";
export const localStorageKeyTerminalId: LocalStorageBaseKey = "TerminalId";

export const loginManager = async (
  user: string,
  password: string,
  deviceId: string
): Promise<User | false> => {
  try {
    const { status, data } = await api.post("/login/totem", {
      username: user,
      password,
      deviceId,
    });

    if (status !== 200) throw new Error();

    const { accessToken, refreshToken, terminal_id: terminalId } = data;

    const { id, username, role } = data.user;

    const { id: entityId } = data.entity;

    const _user = {
      id,
      username,
      role,
      entity: entityId,
      token: accessToken,
      refreshToken,
      terminalId,
    };

    return _user;
  } catch (error) {
    return false;
  }
};

export const loginAdmin = async (entity: string, password: Array<Array<string>>): Promise< AdminEntity | boolean> => {
  try {
    const { status, data } = await api.post("/login/authorization-code", {
      entity,
      password,
    });

    if (status !== 200) throw new Error();

    return data?.adminEntity;
  } catch (error) {
    return false;
  }
};

export const loginRefreshToken = async (refreshToken: string): Promise<string | false> => {
  try {
    const { status, data } = await api.post("/login/refresh-token", {
      refreshToken,
    });

    if (status !== 201) throw new Error();

    const { accessToken } = data;

    return accessToken;
  } catch (error) {
    return false;
  }
};

export const logoutManager = () => {
  deleteLocalStorage(localStorageKeyUserToken);

  deleteLocalStorage(localStorageKeyUserRefreshToken);
};

export const logoutClient = async (totem: string, entity: string) => {
  await api.post("/login/mobile/logout", {
    totem,
    entity,
  });
};

export const loginTotem = async (
  username: string,
  password: Array<Array<string>>,
): Promise<Totem | false> => {
  try {
    const { status, data } = await api.post("/login/pin-code", {
      username: `${username}@menupass.com.br`,
      password,
    });

    if (status !== 200) throw new Error();

    const {
      id, identityNumber, picture, name, isActive, role, paymentInfos, email,
    } = data;

    const { id: userId } = data.user;

    const { id: virtualCardId, balance, minimumBalance } = data.virtualCard;

    if (!isActive) throw new Error();

    const wallet = await getWalletByClient(id) as Wallet;

    if (!wallet) throw new Error();

    const _user = {
      id,
      identityNumber,
      picture,
      name,
      wallet,
      role,
      paymentInfos,
      email,
      userId,
      virtualCard: {
        id: virtualCardId,
        balance,
        minimumBalance,
      },
    };

    return _user;
  } catch (error) {
    return false;
  }
};

export const setLocalStorageUserManager = (user: User) => setLocalStorage(localStorageKeyUserManager, user);
export const getLocalStorageUserManager = (): User => getLocalStorage(localStorageKeyUserManager);
export const cleanLocalStorageUserManager = () => deleteLocalStorage(localStorageKeyUserManager);

export const setLocalStorageUserTotem = (totem: Totem) => setLocalStorage(localStorageKeyUserTotem, totem);
export const getLocalStorageUserTotem = (): Totem => getLocalStorage(localStorageKeyUserTotem);
export const cleanLocalStorageUserTotem = () => deleteLocalStorage(localStorageKeyUserTotem);

export const setLocalStorageToken = (token: string) => setLocalStorage(localStorageKeyUserToken, token);
export const getLocalStorageToken = (): string | null => getLocalStorage(localStorageKeyUserToken);
export const cleanLocalStorageToken = () => deleteLocalStorage(localStorageKeyUserToken);

export const setLocalStorageRefreshToken = (refreshToken: string) => setLocalStorage(localStorageKeyUserRefreshToken, refreshToken);
export const getLocalStorageRefreshToken = (): string | null => getLocalStorage(localStorageKeyUserRefreshToken);
export const cleanLocalStorageRefreshToken = () => deleteLocalStorage(localStorageKeyUserRefreshToken);

export const setTerminalId = (terminalId: string) => setLocalStorage(localStorageKeyTerminalId, terminalId);
export const getTerminalId = (): string | null => getLocalStorage(localStorageKeyTerminalId);

export const loginFaceRecognition = async (
  dto: CheckFace
): Promise<Totem | false> => {
  try {
    const { status, data } = await api.post("face-recognition/check-face", dto);
    if (status !== 200) throw new Error();

    const {
      id,
      identityNumber,
      picture,
      name,
      isActive,
      role,
      paymentInfos,
      email,
    } = data;

    const { id: userId } = data.user;

    const { id: virtualCardId, balance, minimumBalance } = data.virtualCard;

    if (!isActive) throw new Error();

    const wallet = (await getWalletByClient(id)) as Wallet;
    if (!wallet) throw new Error();

    const _user: Totem = {
      id,
      identityNumber,
      picture,
      name,
      wallet,
      role,
      paymentInfos,
      email,
      userId,
      virtualCard: {
        id: virtualCardId,
        balance,
        minimumBalance,
      },
    };

    return _user;
  } catch (error) {
    console.log("error", error);
    return false;
  }
};
