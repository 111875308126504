import {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";

import {
  logoutManager as logoutService,
  setLocalStorageToken,
  setLocalStorageRefreshToken,
  setLocalStorageUserManager,
  getLocalStorageUserManager,
  cleanLocalStorageUserManager,
  cleanLocalStorageToken,
  cleanLocalStorageRefreshToken,
  loginRefreshToken,
  setTerminalId,
} from "../../services";

import { RoleType, User } from "../../types";

export interface UserStoreDefault {
  login: (user: User) => void;
  logout: () => void;
  callRefreshToken: () => void;
}

export type UserManagerStore = User & UserStoreDefault;

export function useUserManager() {
  const [id, setId] = useState<string>("");
  const [role, setRole] = useState<RoleType | null>(null);
  const [username, setUsername] = useState<string>("");
  const [entity, setEntity] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [refreshToken, setRefreshToken] = useState<string>("");

  const login = (user: User) => {
    setId(user.id);
    setRole(user.role);
    setUsername(user.username);

    setToken(user.token);
    setRefreshToken(user.refreshToken);

    setEntity(user.entity);

    setLocalStorageToken(user.token);
    setLocalStorageRefreshToken(user.refreshToken);

    setTerminalId(user.terminalId || "");

    setLocalStorageUserManager(user);
  };

  const logout = async () => {
    setId("");
    setRole("totem");
    setUsername("");

    setToken("");
    setRefreshToken("");

    setEntity("");

    logoutService();

    cleanLocalStorageUserManager();
    cleanLocalStorageToken();
    cleanLocalStorageRefreshToken();
  };

  const callRefreshToken = useCallback(async () => {
    const _token = await loginRefreshToken(refreshToken);

    if (_token) {
      setToken(_token);

      const _user = getLocalStorageUserManager();

      _user.token = _token;

      setLocalStorageUserManager(_user);
      setLocalStorageToken(_token);
    }
  }, [refreshToken]);

  useEffect(() => {
    const _userManager = getLocalStorageUserManager();

    if (_userManager) {
      login(_userManager);
    }
  }, []);

  return useMemo(
    () => ({
      id,
      role,
      username,
      token,
      refreshToken,
      entity,
      login,
      logout,
      callRefreshToken,
    }),
    [id, role, username, token, refreshToken, entity, login, logout, callRefreshToken]
  );
}
