import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalView: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: 345,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 18,
    padding: "52px 30px 60px 30px",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  message: {
    fontSize: 16,
    width: 145,
    textAlign: "center",
    marginTop: 15,
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 30,
    gap: 8,
  },
  spacer30: {
    marginTop: 30,
  },
  spacer20: {
    marginTop: 20,
  },
  spacer10: {
    marginTop: 10,
  },
}));
export default useStyles;
