import { makeStyles, Theme } from "@material-ui/core/styles";

import { Product } from "../../../types";

type DynamicStylesProps = Partial<Product>;

const modalVerticalPadding = 65;
const totalMarginHorizontal = 30;

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  modalView: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: 345,
    height: 610,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 18,
    paddingTop: modalVerticalPadding,
    paddingBottom: modalVerticalPadding,
  },

  close: {
    position: "absolute",
    top: 15,
    right: 15,
    width: 24,
    height: 24,
  },

  info: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",

    "& span": {
      fontSize: 18,
    },
  },

  image: {
    width: 200,
    height: 200,
    borderRadius: 32,
    marginBottom: 10,
    backgroundImage: ({ picture }: DynamicStylesProps) => `url(${picture})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
    boxShadow:
      "0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08)",
  },

  observation: {
    marginBottom: 30,
  },

  orderView: {
    width: 200,
  },

  amount: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 46,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 16,
    border: "2px solid #E5E5E8",
  },

  icon: {
    width: 24,
    height: 24,
  },

  iconDisabled: {
    opacity: 0.2,
  },

  total: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: totalMarginHorizontal,
    marginBottom: totalMarginHorizontal,

    "& span": {
      fontSize: 22,
      fontWeight: "bold",
    },
  },

  addToCart: {
    width: "100% !important",
  },
}));

export default useStyles;
