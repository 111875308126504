import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@material-ui/core";

import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  Button,
  FormField,
  FormFieldSignature,
  SwitchOptions,
  Option,
  LoadingModal,
} from "../../components";
import {
  ConfirmCreditPurchaseSchemaInterface,
  ConfirmCreditPurchaseSchema,
  ConfirmManualPurchaseSchemaInterface,
  ConfirmManualPurchaseSchema,
  ConfirmVariablePricePurchaseSchema,
} from "../../schemas";
import { useStore } from "../../store";

import useStyles from "./styles";
import { createOrder, getAllClientsByEntity } from "../../services";
import { Clients, PaymentMethod } from "../../types";
import { FormFieldSignatureVariablePrice } from "../../components/FormFieldSignatureVariablePrice";
import { getPaymentMethodName } from "../../utils/getPaymentMethodName";

type SelectedTab = "Cadastrado" | "Não Cadastrado";
type SelectedTabVP = "Assinatura" | "Foto";

const options: Option[] = [
  {
    label: "Cadastrado",
    disabled: false,
  },

  {
    label: "Não Cadastrado",
    disabled: false,
  },
];
const optionsVP: Option[] = [
  {
    label: "Assinatura",
    disabled: false,
  },

  {
    label: "Foto",
    disabled: false,
  },
];

const initialLoginFormValues: ConfirmCreditPurchaseSchemaInterface = {
  clientName: '',
  clientSignature: '',
};

export const ConfirmManualPurchase: React.FC = () => {
  const {
    state,
  }: any = useLocation();

  switch (state?.type) {
    case "on-credit":
      return <CreditPurchase />;

    case "manual":
      return <ManualPurchase />;

    case "variablePrice":
      return <VariablePriceCreditPurchase />;

    case "visitor":
      return <VisitorPurchase />;

    default:
      return <ManualPurchase />;
  }
};

const CreditPurchase: React.FC = () => {
  const Styles = useStyles();

  const { navigation, userManager, order } = useStore();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid }
  } =
    useForm<ConfirmCreditPurchaseSchemaInterface>({
      mode: "onChange",
      resolver: yupResolver(ConfirmCreditPurchaseSchema),
      defaultValues: initialLoginFormValues,
    });

  const [selectedTab, setSelectedTab] = useState<SelectedTab>("Cadastrado");

  const [clients, setClients] = useState<Clients>([]);
  const [clientId, setClientId] = useState<string>('');

  const handleBack = () => {
    reset(initialLoginFormValues);

    navigation.navigateTo('/manual-purchase');
  };

  const onSubmit = async (data: ConfirmCreditPurchaseSchemaInterface) => {
    const {
      clientSignature,
    } = data;

    const clientSelected = clients.find((client) => client.id === clientId);

    if (clientSelected && clientId && selectedTab === 'Cadastrado') {
      const admin = clientSelected?.admins?.[0];
      order.setFinalDataIsOnCreditOrder(clientSignature, clientSelected.name, clientId, admin?.name || '', admin?.email || '', admin?.phone || '');
    } else {
      order.setFinalDataIsOnCreditOrder(clientSignature, data.clientName);
    }

    navigation.navigateTo('/login-admin', {
      state: {
        ismanual: false
      }
    });
  };

  const getClients = async () => {
    const res = await getAllClientsByEntity(userManager.entity);

    if (res) setClients(res);
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.formView}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography className={Styles.title}>Confirmar Compra Fiado</Typography>

          <SwitchOptions
            label="Tipo de Cadastro"
            value={selectedTab}
            options={options}
            onChange={(value) => setSelectedTab(value as SelectedTab)}
          />

          {
            selectedTab === "Cadastrado"
              ? (
                <FormField
                  control={control}
                  name="clientName"
                  label="Usuário"
                  placeholder="Digite para buscar"
                  options={clients}
                  setValue={setClientId}
                />
              )
              : (
                <FormField
                  control={control}
                  name="clientName"
                  label="Usuário"
                  placeholder="Insira o nome do usuário"
                />
              )
          }

          <FormFieldSignature
            control={control}
            name="clientSignature"
            label="Assinatura"
          />

          <div className={Styles.actions}>
            <Button
              type="submit"
              size="large"
              fullWidth
              disabled={!isValid}
            >
              Continuar
            </Button>

            <Button
              color="secondary"
              size="large"
              fullWidth
              onClick={() => handleBack()}
            >
              Voltar
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const VariablePriceCreditPurchase: React.FC = () => {
  const Styles = useStyles();

  const {
    state,
  }: any = useLocation();

  const {
    navigation, userManager, order, userTotem
  } = useStore();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid }
  } =
    useForm<ConfirmCreditPurchaseSchemaInterface>({
      mode: "onChange",
      resolver: yupResolver(state.typeVariablePrice === 'notManualCreditVariablePrice' ? ConfirmVariablePricePurchaseSchema : ConfirmCreditPurchaseSchema),
      defaultValues: initialLoginFormValues,
    });

  const [selectedTab, setSelectedTab] = useState<SelectedTab>("Cadastrado");
  const [selectedTabVP, setSelectedTabVP] = useState<SelectedTabVP>("Assinatura");
  const [imageCache, setImageCache] = useState('');
  const [modalMessage, setModalMessage] = useState<string>("");
  const [isValid_, setIsValid_] = useState(false);

  const [clients, setClients] = useState<Clients>([]);
  const [clientId, setClientId] = useState<string>('');

  const handleBack = async () => {
    reset(initialLoginFormValues);

    if (order.items?.length > 0) {
      navigation.navigateTo('/product-variable-price-purchase', {
        state: {
          product: order.items[0]
        }
      });
    } else {
      await userTotem.logout(true);
      navigation.navigateTo('/variable-price-purchase');
    }
  };

  const onSubmit = async (data: ConfirmCreditPurchaseSchemaInterface) => {
    const {
      clientSignature,
    } = data;

    if (state.typeVariablePrice === 'notManualCreditVariablePrice') {
      const payload = {
        entity: userManager.entity,
        virtualCard: userTotem.virtualCard?.id,
        totalAmount: order.totalAmount,
        clientName: userTotem.name,
        isOnCredit: false,
        isVariablePrice: true,
        items: order.items.map((item) => {
          const {
            id,
            name,
            amount,
            totalAmount,
            price,
            observation,
          } = item;

          return {
            id,
            name,
            quantity: amount,
            price,
            totalAmount,
            observation,
          };
        }),
      };

      const res = await createOrder(payload);

      order.reset();
      await userTotem.logout(true);

      if (res) {
        setModalMessage("Compra Finalizada");

        setTimeout(() => {
          navigation.navigateTo("/variable-price-purchase");

          setModalMessage("");
        }, 3000);
      } else {
        setModalMessage("Falha ao realizar seu pedido");

        setTimeout(() => {
          navigation.navigateTo("/variable-price-purchase");

          setModalMessage("");
        }, 3000);
      }
    }

    if (state.typeVariablePrice !== 'notManualCreditVariablePrice') {
      const clientSelected = clients.find((client) => client.id === clientId);

      if (clientSelected && clientId && selectedTab === 'Cadastrado') {
        const admin = clientSelected?.admins?.[0];
        order.setFinalDataIsOnCreditOrder(imageCache || clientSignature, clientSelected.name, clientId, admin?.name || '', admin?.email || '', admin?.phone || '');
      } else {
        order.setFinalDataIsOnCreditOrder(imageCache || clientSignature, data.clientName);
      }

      navigation.navigateTo('/login-admin', {
        state: {
          ismanual: state.typeVariablePrice === 'manualVariablePrice',
          isVariablePrice: true,
        }
      });
    }
  };

  const onImagePick = (file: File, uri: string) => {
    setImageCache(uri);
  };

  const getClients = async () => {
    const res = await getAllClientsByEntity(userManager.entity);

    if (res) setClients(res);
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <div className={Styles.variablePriceContainer}>
      <div className={Styles.formView}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {state.typeVariablePrice === 'creditVariablePrice' && (
            <Typography className={Styles.title}>Confirmar Compra Fiado</Typography>
          )}
          {state.typeVariablePrice === 'manualVariablePrice' && (
            <Typography className={Styles.title}>Compra Manual</Typography>
          )}
          {state.typeVariablePrice === 'notManualCreditVariablePrice' && (
            <Typography className={Styles.title}>Finalizar compra</Typography>
          )}

          {state.typeVariablePrice === 'creditVariablePrice' && (
            <SwitchOptions
              label="Tipo de Cadastro"
              value={selectedTab}
              options={options}
              onChange={(value) => setSelectedTab(value as SelectedTab)}
            />
          )}

          <SwitchOptions
            label="Tipo de opção"
            value={selectedTabVP}
            options={optionsVP}
            onChange={(value) => setSelectedTabVP(value as SelectedTabVP)}
          />

          {
            selectedTab === "Cadastrado" && state.typeVariablePrice === 'creditVariablePrice'
              ? (
                <FormField
                  control={control}
                  name="clientName"
                  label="Usuário"
                  placeholder="Digite para buscar"
                  options={clients}
                  setValue={setClientId}
                />
              )
              : state.typeVariablePrice !== 'notManualCreditVariablePrice' && (
                <FormField
                  control={control}
                  name="clientName"
                  label="Usuário"
                  placeholder="Insira o nome do usuário"
                />
              )
          }

          {selectedTabVP === "Assinatura" ? (
            <FormFieldSignature
              control={control}
              name="clientSignature"
              label="Assinatura"
            />

          ) : (
            <div className={Styles.uploadImg}>
              <FormFieldSignatureVariablePrice control={control} onFileSelect={onImagePick} uri={imageCache} name="clientSignature" />
            </div>
          )}

          <div className={Styles.actions}>
            <Button
              type="submit"
              size="large"
              fullWidth
              disabled={!isValid || isValid_}
              onClick={() => setIsValid_(true)}
            >
              Continuar
            </Button>

            <Button
              color="secondary"
              size="large"
              fullWidth
              onClick={() => handleBack()}
            >
              Voltar
            </Button>
          </div>

          <LoadingModal
            open={!!modalMessage}
            message={modalMessage}
          />
        </form>
      </div>
    </div>
  );
};

const ManualPurchase: React.FC = () => {
  const Styles = useStyles();

  const { navigation, order } = useStore();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid }
  } =
    useForm<ConfirmManualPurchaseSchemaInterface>({
      mode: "onChange",
      resolver: yupResolver(ConfirmManualPurchaseSchema),
      defaultValues: initialLoginFormValues,
    });

  const handleBack = () => {
    reset(initialLoginFormValues);

    navigation.navigateTo('/manual-purchase');
  };

  const onSubmit = async (data: ConfirmManualPurchaseSchemaInterface) => {
    const {
      clientName,
    } = data;

    order.setFinalDataIsManualOrder(clientName);

    navigation.navigateTo('/login-admin', {
      state: {
        ismanual: true
      }
    });
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.formView}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography className={Styles.title}>Compra Manual</Typography>

          <FormField
            control={control}
            name="clientName"
            label="Usuário"
            placeholder="Insira o nome do usuário"
          />

          <div className={Styles.actions}>
            <Button
              type="submit"
              size="large"
              fullWidth
              disabled={!isValid}
            >
              Continuar
            </Button>

            <Button
              color="secondary"
              size="large"
              fullWidth
              onClick={() => handleBack()}
            >
              Voltar
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const VisitorPurchase: React.FC = () => {
  const Styles = useStyles();

  const { navigation, order } = useStore();
  const {
    state: {
      paymentMethod
    }
  }: {
    state: {
      paymentMethod?: PaymentMethod,
    }
  } = useLocation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid }
  } =
    useForm<ConfirmManualPurchaseSchemaInterface>({
      mode: "onChange",
      resolver: yupResolver(ConfirmManualPurchaseSchema),
      defaultValues: initialLoginFormValues,
    });

  const handleBack = (visitor?: boolean) => {
    reset(initialLoginFormValues);

    navigation.navigateTo(visitor ? '/visitor-purchase' : '/manual-purchase');
  };

  const onSubmit = async (data: ConfirmManualPurchaseSchemaInterface) => {
    const {
      clientName,
    } = data;

    order.setFinalDataIsManualOrder(clientName);

    navigation.navigateTo('/visitor-confirm-purchase', {
      state: {
        paymentMethod,
      },
    });
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.formView}>
        <form className={Styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
          <Typography className={Styles.title}>
            {`Compra Visitante - ${getPaymentMethodName(paymentMethod)}`}
          </Typography>

          <FormField
            control={control}
            name="clientName"
            label="Cliente"
            placeholder="Insira o nome"
          />

          <div className={Styles.actions}>
            <Button
              type="submit"
              size="large"
              fullWidth
              disabled={!isValid}
            >
              Continuar
            </Button>

            <Button
              color="secondary"
              size="large"
              fullWidth
              onClick={() => handleBack(true)}
            >
              Voltar
            </Button>
          </div>

        </form>
      </div>
    </div>
  );
};
